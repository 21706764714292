import { postSearchAlgoliaFacetQueryFiltersInternal } from 'modules/postSearch/helpers/postSearchAlgoliaFacetQueryFiltersInternal';
import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { PostSearchFilters } from 'modules/postSearch/types/PostSearchFilters';

export function postSearchAlgoliaGetFacetQueryFilters(
  filters: PostSearchFilters,
  searchFacets: PostSearchFacetData[],
  locale: string,
): Array<string> {
  return postSearchAlgoliaFacetQueryFiltersInternal(
    filters,
    searchFacets,
  ).concat(['pageType:POST', `locale:${locale}`]);
}
