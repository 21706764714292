import { sprintf } from 'sprintf-js';

type Props = {
  pageIndex: number;
  hitsPerPage: number;
  nbHits: number;
};

export function getResultsDescription({
  pageIndex,
  hitsPerPage,
  nbHits,
}: Props) {
  return sprintf(getText('%(first)s-%(last)s of %(total)s'), {
    first: (pageIndex * hitsPerPage + 1).toLocaleString(),
    last: Math.min((pageIndex + 1) * hitsPerPage, nbHits).toLocaleString(),
    total: nbHits.toLocaleString(),
  });
}
