import styled from 'styled-components';

import { cssBreakpoints } from 'theme/theme';

export const SearchFilterSectionFilters = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  overflow: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    flex-direction: column;
  }
`;
