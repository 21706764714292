import deepEqual from 'react-fast-compare';
import { sprintf } from 'sprintf-js';
import styled from 'styled-components';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { Divider } from 'components/Divider';
import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';
import { Icon } from 'components/Icon/Icon';
import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { Pagination } from 'components/Pagination';
import { Pill } from 'components/Pill';
import { BodySmall } from 'components/Text/BodySmall';
import { H1 } from 'components/Text/H1';
import { HeadingSmall } from 'components/Text/HeadingSmall';
import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import PostSearchInputContainer from 'containers/PostSearchInputContainer';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { CmsButton } from 'modules/cms/components/Button/CmsButton';
import { useCmsSubscribeToSubsite } from 'modules/cms/hooks/useCmsSubscribeToSubsite';
import { PostSearchResult } from 'modules/postSearch/components/Result/PostSearchResult';
import { PostSearchFiltersSidebarContainer } from 'modules/postSearch/containers/PostSearchFiltersSidebarContainer';
import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import {
  PostSearchFacetKeys,
  MainStorePostSearchState as PostSearchState,
} from 'store/postSearch/postSearch';
import {
  deselectPostSearchFilterOption,
  selectAllPostSearchFilterOptions,
} from 'store/postSearch/postSearch.actions';
import { colors, cssBreakpoints, gutterWidth, maxWidth } from 'theme/theme';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { sort as sortFn } from 'utils/functional';
import { scrollToTop } from 'utils/scroll';

import { PostSearchEmptyResultsMessage } from './PostSearchEmptyResultsMessage';

type Props = {
  search: PostSearchState;
  homePageUrl: string;
  subsite: CmsApiSubsite;
  parentSubsite: CmsApiSubsite | null | undefined;
};

const FiltersContainer = styled.div`
  border-top: 1px solid ${colors.selectionGrey};
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
`;

const FilterPillContainer = styled.div`
  padding: 4px 2px;
`;

const SubscribeButtonContents = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

function FilterPill({
  text,
  clearFilter,
}: {
  text: string;
  clearFilter: () => void;
}) {
  return (
    <FilterPillContainer data-qa-id="filter-pill">
      <Pill
        onClick={clearFilter}
        title={sprintf(getText(`Clear %(option)s filter`), {
          option: text,
        })}
        text={text}
      />
    </FilterPillContainer>
  );
}

const SearchResultsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: ${maxWidth[3]}px;
  margin: 0 auto;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    margin-top: 72px;
    width: 100%;
    height: calc(100% + ${gutterWidth / 2}px);
  }

  @media all and (min-width: ${cssBreakpoints.lgUp}) {
    &::before {
      box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
      border-radius: 12px;
    }
  }
`;

export function PostSearchResults({
  search,
  homePageUrl,
  subsite,
  parentSubsite,
}: Props) {
  const dispatch = useMainDispatch();

  const { subscribe, isSubscribed } = useCmsSubscribeToSubsite(subsite.id);

  const {
    pageIndex,
    resultsByPage,
    queryID,
    isSearching,
    initialized,
    filters,
    searchFacets,
    query,
  } = search;
  const results = resultsByPage[pageIndex.toString()];
  const { hits, nbHits, hitsPerPage, page, nbPages } = results || {};
  const categoryOptionTitles =
    searchFacets.articleCategoriesFacet?.optionTitles;
  const searchingAuthors = Boolean(filters.articleAuthorFacet);
  const searchingTags = Boolean(filters.articleTagsFacet);
  const showFeaturedSearch =
    pageIndex === 0 &&
    !(query?.length || searchingAuthors || searchingTags) &&
    subsite.hasFeaturedPostInSearchResults;

  const allOptionsSelected = ({
    searchFilter,
    searchFacet,
  }: {
    searchFilter: string[];
    searchFacet: PostSearchFacetData;
  }) =>
    (searchFilter &&
      Array.isArray(searchFilter) &&
      searchFilter.length > 0 &&
      deepEqual(sortFn(searchFilter), sortFn(searchFacet.options)) &&
      searchFacet.id === 'articleCategoriesFacet') ||
    !searchFilter;

  const chips = searchFacets
    ? (Object.keys(searchFacets) as PostSearchFacetKeys[])
        .filter(
          (facetKey) =>
            filters[facetKey] &&
            filters[facetKey].length > 0 &&
            !allOptionsSelected({
              searchFilter: filters[facetKey],
              searchFacet: searchFacets[facetKey],
            }),
        )
        .map((facetKey) => {
          const facet = searchFacets[facetKey];
          const filter = filters[facetKey];

          if (Array.isArray(filter)) {
            return filter.map((option) => (
              <FilterPill
                key={option}
                text={facet.optionTitles[option]}
                clearFilter={() => {
                  const isSelected =
                    filter?.includes(option) &&
                    !allOptionsSelected({
                      searchFilter: filter,
                      searchFacet: searchFacets[facetKey],
                    });

                  return isSelected &&
                    deepEqual([option], filter) &&
                    searchFacets[facetKey].options.length > 1
                    ? dispatch(selectAllPostSearchFilterOptions(facetKey))
                    : dispatch(
                        deselectPostSearchFilterOption(facetKey, option),
                      );
                }}
              />
            ));
          }

          return null;
        })
    : [];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/function-component-definition, react/no-unstable-nested-components
  const SearchResultsBody = () =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    hits && hits.length > 0 && queryID ? (
      <>
        <Box pb={16} mb={16}>
          <BodySmall>
            {sprintf(getText('%(first)s-%(last)s of %(total)s %(word)s'), {
              first: (page * hitsPerPage + 1).toLocaleString(),
              last: Math.min((page + 1) * hitsPerPage, nbHits).toLocaleString(),
              total: nbHits.toLocaleString(),
              word: hits.length > 1 ? getText('articles') : getText('article'),
            })}
          </BodySmall>
        </Box>
        {showFeaturedSearch && (
          <>
            <PostSearchResult
              post={hits[0]}
              homePageUrl={homePageUrl}
              featured
              categories={categoryOptionTitles}
              index={0}
              hitsPerPage={hitsPerPage}
              page={page}
              queryID={queryID}
              subsite={subsite}
              data-qa-id="search-result"
            />

            {hits.length > 1 && (
              <Box my={[24, null, null, 70]}>
                <Divider marginTop={0} marginBottom={0} />
              </Box>
            )}
          </>
        )}
        <Box
          mt={showFeaturedSearch ? 0 : -24}
          mx={-24}
          display="flex"
          flexWrap="wrap"
        >
          {hits.slice(showFeaturedSearch ? 1 : 0).map((hit, index) => (
            <Box p={24} key={hit.objectID} width={[1, 1 / 2]} flex="0 0 auto">
              <PostSearchResult
                post={hit}
                homePageUrl={homePageUrl}
                categories={categoryOptionTitles}
                index={index}
                hitsPerPage={hitsPerPage}
                page={page}
                queryID={queryID}
                subsite={subsite}
                data-qa-id="search-result"
              />
            </Box>
          ))}
        </Box>
        {typeof nbPages === 'number' &&
          typeof page === 'number' &&
          typeof hitsPerPage === 'number' && (
            <Pagination
              nbHits={nbHits}
              nbPages={nbPages}
              pageIndex={page}
              hitsPerPage={hitsPerPage}
              onClick={scrollToTop}
            />
          )}
      </>
    ) : isSearching || !initialized ? (
      <LoadingSection />
    ) : (
      <PostSearchEmptyResultsMessage />
    );

  return (
    <SearchResultsContainer>
      <Box
        data-qa-id="search-sidebar"
        width={[1, 1, 1, 1 / 3]}
        px={[gutterWidth / 2, null, null, gutterWidth]}
      >
        <Box data-qa-id="search-results-title">
          {parentSubsite?.homePage && (
            <HeadingSmall fontWeight="semiBold">
              <VersionedRouterLink
                to={`/${parentSubsite.homePage.url}?pq=`}
                state={{ parentSubsite: null }}
                style={{ textDecoration: 'none' }}
                data-qa-id="back-to-subsite-link"
              >
                <Icon name="chevron-left" size={10} />
                <Box component="span" ml="4px">
                  {sprintf(getText('Back to %(name)s'), {
                    name: parentSubsite.publicName,
                  })}
                </Box>
              </VersionedRouterLink>
            </HeadingSmall>
          )}
          <H1 mb={20} mt={0}>
            {subsite.publicName}
          </H1>
          {subsite.searchBlurb ? (
            <Box
              position="relative"
              borderBottom={`1px solid ${colors.selectionGrey}`}
              pb="24px"
              mb="24px"
            >
              <BodySmall color={colors.lightContentGrey}>
                {subsite.searchBlurb}
              </BodySmall>
            </Box>
          ) : undefined}
        </Box>
        {chips.length > 0 ? <FiltersContainer>{chips}</FiltersContainer> : null}
        <div
          style={{
            position: 'relative',
            borderBottom: `1px solid ${colors.selectionGrey}`,
            paddingBottom: '24px',
          }}
        >
          <PostSearchInputContainer
            homePageUrl={homePageUrl}
            searchPlaceholderText={subsite.searchPlaceholderText}
            usageContext="searchResults"
          />
        </div>
        <div>
          <PostSearchFiltersSidebarContainer subsite={subsite} />
        </div>
        {(subsite.canSubscribe ||
          (subsite.searchLinks && subsite.searchLinks.length > 0)) && (
          <>
            <Box display="flex" alignItems="center">
              {subsite.canSubscribe && (
                <Box mb="24px" mr="12px">
                  <Button
                    type="button"
                    variant="tertiary"
                    onClick={() => {
                      subscribe();
                      trackClicked('Subscribe', {
                        context: 'CMS Search Results',
                      });
                    }}
                    data-qa-id="subscribe-to-subsite"
                    size="medium"
                    disabled={isSubscribed}
                  >
                    <SubscribeButtonContents>
                      {isSubscribed && (
                        <AnimatedCheckmark colorName="brandGreen" size={16} />
                      )}
                      <div>
                        {isSubscribed
                          ? getText('Subscribed!')
                          : subsite.subscribeText ||
                            getText('Subscribe to Our Newsletter')}
                      </div>
                      {!isSubscribed && <Icon size={16} name="arrow-right" />}
                    </SubscribeButtonContents>
                  </Button>
                </Box>
              )}
              {subsite.searchLinks.map((searchLink, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box mb="24px" mx="12px" key={`searchLink.${index}`}>
                  <CmsButton
                    buttonData={searchLink}
                    eventProperties={{
                      search_link_button_type: searchLink.type,
                    }}
                    render={({ data, children, title, 'data-qa-id': qaId }) => (
                      <Button
                        data={data}
                        title={title}
                        variant="tertiary"
                        noPadding
                        data-qa-id={qaId}
                      >
                        {children}
                      </Button>
                    )}
                  />
                </Box>
              ))}
            </Box>
            {chips.length === 0 ? (
              <Divider marginTop={0} marginBottom={24} />
            ) : null}
          </>
        )}
      </Box>
      <Box
        width={[1, 1, 1, 2 / 3]}
        px={[gutterWidth / 2, null, null, gutterWidth]}
        pt={[gutterWidth, null, null, 0]}
        data-qa-id="search-results"
      >
        <SearchResultsBody />
      </Box>
    </SearchResultsContainer>
  );
}
