import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { Color } from 'theme/theme';
import { imageUrl } from 'utils/filestack';

import { MaskImageIcon } from './MaskImageIcon';

type Props = {
  altText?: string;
  data: ApiFilestackImageData | undefined;
  size: number;
  colorName: Color;
  opacity?: number;
  qaId?: string;
};

export function FilestackIcon({
  data,
  size,
  altText,
  colorName,
  opacity,
  qaId,
}: Props) {
  if (!data) return null;

  return (
    <MaskImageIcon
      src={imageUrl({
        data,
        width: size,
        height: size,
        fit: 'max',
        insecureSVG: false,
      })}
      size={size}
      opacity={opacity}
      colorName={colorName}
      alt={altText ? `${altText} icon` : ''}
      qaId={qaId}
    />
  );
}
