import queryString from 'query-string';

import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { PostSearchFilters } from 'modules/postSearch/types/PostSearchFilters';
import { filterObject } from 'utils/functional';

import { postSearchMakeSerializedUrlFilters } from './postSearchMakeSerializedUrlFilters';

export function postSearchGetQueryString(
  filters: PostSearchFilters,
  searchFacets: Record<string, PostSearchFacetData>,
  query: string,
  currentSearchParams: string,
) {
  const filtersToSerialize = filterObject(
    filters,
    (k, v) =>
      Boolean(v) &&
      (!(typeof v === 'string' || v instanceof Array) || v.length > 0),
  );

  const initialQueryParams = queryString.parse(currentSearchParams);

  const serializedFilters = postSearchMakeSerializedUrlFilters({
    filters: filtersToSerialize,
    searchFacets,
    initialQueryParams,
  });
  const utmParams = filterObject(queryString.parse(currentSearchParams), (k) =>
    k.startsWith('utm'),
  );
  const searchParams = {
    pq: query,
    ...utmParams,
    ...serializedFilters,
  };
  return queryString.stringify(searchParams);
}
