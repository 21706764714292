import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from 'hooks/usePrevious';
import { CmsApiPage } from 'modules/cms/api/types/CmsApiPage';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { PostSearchContainer } from 'modules/postSearch/containers/PostSearch/PostSearchContainer';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useMainSelector } from 'store/hooks/useMainSelector';
import { initializePostSearch } from 'store/postSearch/postSearch.actions';
import { setDocumentTitle } from 'utils/meta/setDocumentTitle';

type Props = {
  content: CmsApiPage;
};

type LocationParams = {
  parentSubsite?: CmsApiSubsite;
  query?: string;
  searchLocation?: SearchLocation;
};

export function PostSearchPage({ content }: Props) {
  const location = useLocation<LocationParams>();

  const { postSearch } = useMainSelector((state) => ({
    postSearch: state.postSearch,
  }));

  const postSearchInitialized = postSearch?.initialized || false;

  const { subsite } = content;

  const [initialSubsiteId, setInitialSubsiteId] = useState(subsite?.id);
  const prevState = usePrevious({
    initialSubsiteId,
  });
  const prevInitialSubsiteId = prevState ? prevState.initialSubsiteId : '';
  const dispatch = useMainDispatch();

  const subsiteId = subsite ? subsite.id : undefined;
  useEffect(() => {
    if (content.id) {
      setDocumentTitle(content.headTitle || content.title);
    }
  }, [content]);

  useEffect(() => {
    if (
      content.id &&
      subsiteId &&
      (subsiteId !== prevInitialSubsiteId || !postSearchInitialized)
    ) {
      if (subsiteId !== prevInitialSubsiteId) {
        setInitialSubsiteId(subsiteId); // return;
      }

      dispatch(
        initializePostSearch({
          queryParams: queryString.parse(location.search),
          subsiteId,
        }),
      );
    }
  }, [
    content.id,
    dispatch,
    location,
    location.search,
    postSearchInitialized,
    prevInitialSubsiteId,
    subsiteId,
  ]);
  const parentSubsite = location.state?.parentSubsite
    ? location.state.parentSubsite
    : null;

  return (
    <PostSearchContainer content={content} parentSubsite={parentSubsite} />
  );
}
