import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { FilestackIcon } from 'components/Icon/FilestackIcon';

import {
  PostSearchCategoryButton,
  PostSearchCategoryButtonText,
} from './PostSearchCategory.styled';

type Props = {
  title: string;
  selected: boolean;
  icon: ApiFilestackImageData | null;
  onClick: () => void;
  qaId?: string;
};

export function PostSearchCategory({
  title,
  icon,
  onClick,
  qaId,
  selected,
}: Props) {
  return (
    <PostSearchCategoryButton
      data-qa-id={qaId}
      $selected={selected}
      onClick={onClick}
    >
      {icon ? (
        <FilestackIcon
          data-qa-id="post-search-category-icon"
          data={icon}
          size={24}
          colorName={selected ? 'white' : 'lightContentGrey'}
        />
      ) : null}
      <PostSearchCategoryButtonText>{title}</PostSearchCategoryButtonText>
    </PostSearchCategoryButton>
  );
}
