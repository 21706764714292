import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { FilestackIcon } from 'components/Icon/FilestackIcon';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';

import {
  PostSearchIncludedSubsitesButtonText,
  SubsiteLinkContainer,
} from './SubsiteLink.styled';

type Props = {
  className?: string;
  icon: ApiFilestackImageData | null;
  label: string;
  url: string;
  selected?: boolean;
  parentSubsite: CmsApiSubsite;
};

export function SubsiteLink({
  className,
  icon,
  label,
  url,
  selected,
  parentSubsite,
}: Props) {
  return (
    <SubsiteLinkContainer
      className={className}
      data-qa-id="side-filter"
      data-qa-selected={selected}
      data-qa-value={url}
      to={`/${url}?pq=`}
      state={{
        parentSubsite,
      }}
    >
      {icon ? (
        <FilestackIcon
          data-qa-id="post-search-subsite-icon"
          data={icon}
          size={24}
          colorName={selected ? 'white' : 'lightContentGrey'}
        />
      ) : null}
      <PostSearchIncludedSubsitesButtonText>
        {label}
      </PostSearchIncludedSubsitesButtonText>
    </SubsiteLinkContainer>
  );
}
