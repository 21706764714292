import invariant from 'invariant';

import { configValue } from 'config/appConfigUtils';
import { postJson } from 'utils/http/postJson';

/**
 * Algolia Analytics tracking. Note that position is 1-indexed
 * This function is a no-op if ALGOLIA_ANALYTICS_API_KEY is not set
 * i.e., sandboxes algolia environments like dev
 */
export function algoliaTrackClick(
  queryID: string | undefined,
  objectID: string,
  position: number,
) {
  if (!queryID) return;

  invariant(objectID, 'No object id supplied');
  invariant(position, 'Invalid position supplied');
  const ALGOLIA_CLICKS_URL = 'https://insights.algolia.io/1/searches/click';
  const ANALYTICS_KEY = configValue('algolia', 'analyticsApiKey');
  const headers = {
    'X-Algolia-API-Key': ANALYTICS_KEY || undefined,
    'X-Algolia-Application-Id': configValue('algolia', 'appId') || undefined,
  };
  const data = {
    queryID,
    objectID,
    position,
  };
  return ANALYTICS_KEY && postJson(ALGOLIA_CLICKS_URL, data, { headers });
}
