import invariant from 'invariant';

import { postSearchAlgoliaGetFacetQueryFilters } from 'modules/postSearch/algolia/postSearchAlgoliaGetFacetQueryFilters';
import { postSearchAlgoliaGetIndex } from 'modules/postSearch/algolia/postSearchAlgoliaGetIndex';
import { searchAlgoliaConfig } from 'modules/search/algolia/searchAlgoliaConfig';
import { algoliaSearch } from 'utils/algolia/algoliaSearch';

import { MainStorePostSearchState } from './postSearch';

type Args = {
  postSearch: MainStorePostSearchState;
  pageIndex?: number;
  subsiteId: string;
  includedSubsiteIds: Array<string>;
  locale: string;
};

export function executePostSearchQuery({
  postSearch,
  pageIndex,
  subsiteId,
  includedSubsiteIds,
  locale,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: Args): Promise<any> {
  const {
    pageIndex: pageIndexState,
    query,
    searchId,
    filters,
    searchFacets,
  } = postSearch;

  const timestamp = new Date();
  let index = postSearchAlgoliaGetIndex();

  // use replica that's sorted differently when query is provided by user input
  if (query && query.length > 0 && index !== null && index !== undefined) {
    index = `${index}-relevant`;
  }

  invariant(index, 'search index not retreived');

  return algoliaSearch({
    config: searchAlgoliaConfig(),
    query,
    options: {
      index,
      hitsPerPage: 20,
      page: pageIndex || pageIndexState || 0,
      facets: ['*', 'subsiteId', 'pageType', 'categoryIds', 'locale'],
      facetFilters: [
        [...includedSubsiteIds, subsiteId].map((id) => `subsiteId:${id}`),
      ],
      filters: postSearchAlgoliaGetFacetQueryFilters(
        filters,
        Object.values(searchFacets),
        locale,
      ).join(' AND '),
      attributesToSnippet: ['description:20'],
      attributesToRetrieve: ['*'],
      // TODO: list attributes,
      removeStopWords: true,
      ignorePlurals: true,
      advancedSyntax: true,
      queryLanguages: [locale],
    },
  }).then((results) => ({
    ...results,
    timestamp,
    searchId,
  }));
}
