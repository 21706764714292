import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { ApiISODateTimeNoTzString } from 'api/types/ApiTypedDate';
import { Avatar } from 'components/Avatar/Avatar';
import { Box } from 'components/Box';
import { ConditionalStyledLinkWithRef } from 'components/Link/ConditionalStyledLinkWithRef';
import { Body } from 'components/Text/Body';
import { H4 } from 'components/Text/H4';
import { Text } from 'components/Text/Text';
import { colors } from 'theme/theme';
import { humanDateTime } from 'utils/date';

type Props = {
  slug?: string;
  name?: string;
  image?: ApiFilestackImageData;
  homePageUrl?: string;
  postedDate: ApiISODateTimeNoTzString;
  showDate: boolean;
  small?: boolean;
};

export function Byline({
  slug,
  name,
  image,
  homePageUrl,
  postedDate,
  showDate,
  small,
}: Props) {
  return (
    <Box classes={['flex', 'align-center']} data-qa-id="byline" flex="2">
      {image && name && (
        <Box mr={3} data-qa-id="byline-image">
          <Avatar name={name} image={image} size="sm" />
        </Box>
      )}
      <div>
        {name && (
          <H4 data-qa-id="author-name" m={0} lineHeight={1}>
            <ConditionalStyledLinkWithRef
              data-qa-id="author-name-link"
              link={
                homePageUrl && slug
                  ? `/${homePageUrl}?author.slug=${slug}&pq=`
                  : ''
              }
            >
              <Body
                // @ts-expect-error TS(2322): Type 'false | "bodySmall" | undefined' is not assi... Remove this comment to see the full error message
                styleAs={small && 'bodySmall'}
                lineHeight={1.33}
              >
                {name}
              </Body>
            </ConditionalStyledLinkWithRef>
          </H4>
        )}
        {showDate && (
          <Text
            tag="time"
            color={colors.lightContentGrey}
            styleAs={small ? 'bodyMicro' : 'bodySmall'}
          >
            {humanDateTime(postedDate)}
          </Text>
        )}
      </div>
    </Box>
  );
}
