import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Image } from 'components/Image/Image';
import { cmsApiSubscribeToSubsite } from 'modules/cms/api/cmsApiSubscribeToSubsite';
import { CmsApiSubsiteId } from 'modules/cms/api/types/CmsApiTypedId';
import { useCmsSubscribedSubsitesIncludes } from 'modules/cms/zustand-stores/cmsSubscribedSubsitesStore';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { getStaticImageSrc } from 'utils/getImageSrc';
import { getParam } from 'utils/url/getParam';
import { showModal } from 'zustand-stores/modalStore';

export function useCmsSubscribeToSubsite(subsiteId: CmsApiSubsiteId) {
  const location = useLocation();

  const { isSubscribed } = useCmsSubscribedSubsitesIncludes(subsiteId);
  const [submitting, setSubmitting] = useState(false);
  const { user, isUserLoaded } = useUserEnvironment();

  const subscribe = useCallback(async () => {
    if (user) {
      setSubmitting(true);
      await cmsApiSubscribeToSubsite(subsiteId);
      setSubmitting(false);

      showModal('SUBSCRIBED_TO_SUBSITE', {});
    } else {
      showModal('EMAIL_SIGNUP', {
        subscribedSubsite: subsiteId,
        title: (
          <div style={{ textAlign: 'center' }}>
            {getText('Sign up to subscribe to our newsletter!')}
          </div>
        ),
        image: (
          <div style={{ textAlign: 'center' }}>
            <Image src={getStaticImageSrc('subscribe-signup.svg')} alt="" />
          </div>
        ),
      });
    }
  }, [user, subsiteId]);

  useEffect(() => {
    if (isUserLoaded) {
      const initSubscribe = getParam(location, 'subscribe');

      if (initSubscribe) {
        subscribe();
      }
    }
  }, [isUserLoaded, location, subscribe]);

  return {
    subscribe,
    isSubscribed,
    submitting,
  };
}
