import { Box } from 'components/Box';
import { SearchLoadingSectionLine } from 'modules/search/components/Loading/SearchLoadingSectionLine';
import { colors, gutterWidth } from 'theme/theme';

import { MockPostResults } from './MockPostResults';

export function PostSearchLoadingSection() {
  return (
    <Box display="flex" pt={54} flexWrap="wrap">
      <Box
        px={[gutterWidth / 2, null, null, gutterWidth]}
        width={[1, null, null, 1 / 3]}
        display={['none', null, null, 'block']}
      >
        <SearchLoadingSectionLine height={40} width="75%" />
        <SearchLoadingSectionLine height={40} width="54%" mt={12} />
        <Box borderBottom={`1px solid ${colors.selectionGrey}`} pb={32} pt={16}>
          <SearchLoadingSectionLine width={60} height={12} />
        </Box>
        {new Array(6).fill(null).map((item, index) => (
          <Box
            py={27} // eslint-disable-next-line react/no-array-index-key
            key={index}
            borderBottom={`1px solid ${colors.selectionGrey}`}
          >
            <SearchLoadingSectionLine
              width={`${20 + ((index + 1) % 2) * 20}%`}
              height={9}
            />
          </Box>
        ))}
      </Box>
      <Box
        px={[gutterWidth / 2, null, null, gutterWidth]}
        width={[1, null, null, 2 / 3]}
      >
        <MockPostResults />
      </Box>
    </Box>
  );
}
