import { SyntheticEvent } from 'react';

import { BodySmall } from 'components/Text/BodySmall';

import { CurrentPage, PageLink } from './Pagination.styled';

type Props = {
  href: string;
  pageNumber: number;
  selected: boolean;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (arg0: SyntheticEvent<any>) => void;
};

export function Page({ href, pageNumber, selected, onClick }: Props) {
  if (selected) {
    return (
      <CurrentPage data-qa-id="pagination-current-page">
        <BodySmall>{pageNumber + 1}</BodySmall>
      </CurrentPage>
    );
  }

  return (
    <PageLink data-qa-id="pagination-link" onClick={onClick} href={href}>
      <BodySmall>{pageNumber + 1}</BodySmall>
    </PageLink>
  );
}
