import { startOfToday, sub } from 'date-fns';

import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { PostSearchFilters } from 'modules/postSearch/types/PostSearchFilters';
import { PostSearchFiltersWithType } from 'modules/postSearch/types/PostSearchFiltersWithType';
import { SearchFacetData } from 'modules/search/types/SearchFacetData';
import { isDefined, isEmpty, isNotEmpty, setEquals } from 'utils/functional';

function queryStringFilter(facet: string, s: string) {
  return isNotEmpty(s) ? `${facet}:'${s}'` : '';
}

export function postSearchAlgoliaFacetQueryFiltersInternal(
  filters: PostSearchFilters | PostSearchFiltersWithType,
  facets: PostSearchFacetData[],
): Array<string> {
  const getValue = (
    { id, filterKey, type, name, options }: SearchFacetData,
    value: string | string[],
  ): string => {
    if (name === 'recency') {
      let dtValue;
      if (value === 'PAST_24_HOURS') {
        const yesterday = sub(new Date(), { days: 1 });
        dtValue = Math.floor(yesterday.getTime() / 1000).toString();
      } else if (value === 'PAST_WEEK') {
        const oneWeekAgo = sub(startOfToday(), { weeks: 1 });
        dtValue = Math.floor(oneWeekAgo.getTime() / 1000).toString();
      } else if (value === 'PAST_MONTH') {
        const oneMonthAgo = sub(startOfToday(), { months: 1 });
        dtValue = Math.floor(oneMonthAgo.getTime() / 1000).toString();
      } else {
        throw Error(`Invalid value for recency facet: ${value}`);
      }
      return `published > ${dtValue}`;
    }

    if (type === 'date') {
      return isDefined(value)
        ? Object.entries(value)
            .filter((v) => v[1])
            .map(
              (v) =>
                `${v[0].substring(0, v[0].length - 2)}${
                  v[0].endsWith('GT') ? '>' : '<'
                }${v[1]}`,
            )
            .join(' AND ')
        : '';
    }

    if (type === 'string') {
      return queryStringFilter(filterKey || name, value[0]);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    return typeof value === 'string'
      ? value === 'ALL'
        ? ''
        : queryStringFilter(name, value)
      : // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-nested-ternary
        Array.isArray(value) && type === 'array'
        ? isEmpty(value) ||
          (setEquals(value, options) &&
            id !== 'articleTagsFacet' &&
            id !== 'articleAuthorFacet')
          ? ''
          : `(${value.map((o) => queryStringFilter(name, o)).join(' OR ')})`
        : '';
  };

  const typeFilter =
    'type' in filters &&
    typeof filters.type === 'string' &&
    filters.type !== 'ALL' &&
    isNotEmpty(filters.type)
      ? [queryStringFilter('type', filters.type)]
      : [];

  return (
    facets
      .filter((f) => (filters[f.id] ? filters[f.id] : filters[f.name]))
      // TODO: Fix this the next time the file is edited.
      // @ts-expect-error fix types
      .map((f) => getValue(f, filters[f.id] ? filters[f.id] : filters[f.name]))
      .filter(isNotEmpty)
      .concat(typeFilter)
  );
}
