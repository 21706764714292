import invariant from 'invariant';
import { useCallback, useEffect, useMemo } from 'react';
import deepEqual from 'react-fast-compare';
import { sprintf } from 'sprintf-js';

import { Button } from 'components/Button/Button';
import { Divider } from 'components/Divider';
import { Pill } from 'components/Pill';
import { BodySmall } from 'components/Text/BodySmall';
import { Text } from 'components/Text/Text';
import { CmsApiCategory } from 'modules/cms/api/types/CmsApiCategory';
import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { sort } from 'utils/functional';

import {
  PillHolder,
  PostSearchSideFacetFilterHolder,
  PostSearchSideFacetFilterRelatedHolder,
  SearchFilterSectionFilters,
} from './PostSearchCategories.styled';
import { PostSearchCategory } from './PostSearchCategory';

type Props = {
  facet: PostSearchFacetData<CmsApiCategory[]>;
  categoryLabel?: string;
  selectedOptions: string[];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPostSearchFilter: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectPostSearchFilterOption: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deselectPostSearchFilterOption: (...args: Array<any>) => any;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAllPostSearchFilterOptions: (...args: Array<any>) => any;
};

function isFacetWithDefinedOptionsArray<T>(
  facet: PostSearchFacetData<T>,
): facet is PostSearchFacetData<T> &
  Required<Pick<PostSearchFacetData<T>, 'optionsArray'>> {
  return facet.optionsArray !== undefined;
}

export function PostSearchCategories({
  facet,
  categoryLabel,
  selectedOptions,
  setPostSearchFilter,
  selectPostSearchFilterOption,
  deselectPostSearchFilterOption,
  selectAllPostSearchFilterOptions,
}: Props) {
  if (!isFacetWithDefinedOptionsArray(facet)) {
    throw new Error('optionsArray must be defined for this component');
  }
  const optionsBySlug = facet.optionsArray.reduce<
    Record<string, CmsApiCategory>
  >(
    (acc, current) => ({
      ...acc,
      [current.slug]: current,
    }),
    {},
  );
  const categories = facet.optionsArray.filter(
    (category) => !category.parentId,
  );
  const selectedCategories = categories.filter((category) =>
    selectedOptions.includes(category.slug),
  );
  const subCategories = facet.optionsArray.filter(
    (category) =>
      /* Show only children */
      (category.parentId !== null &&
        /* If current selection is a child, show all siblings */
        selectedOptions.some(
          (selectedOption) =>
            category.parentId === optionsBySlug[selectedOption].parentId,
        )) ||
      /* If current selection is a parent, show all children */
      selectedCategories.some(
        (selectedCategory) => selectedCategory.id === category.parentId,
      ),
  );
  const selectedSubcategories = subCategories.filter((subcategory) =>
    selectedOptions.includes(subcategory.slug),
  );
  const selectedCategoryIds = selectedCategories.map((category) => category.id);
  const selectedCategorySlugs = selectedCategories.map(
    (category) => category.slug,
  );
  const allOptionsSelected = useMemo(
    () => deepEqual(sort(selectedOptions), sort(facet.options)),
    [facet.options, selectedOptions],
  );

  const toggleCategory = ({
    parentId,
    selected,
    slug,
  }: {
    parentId: string | null | undefined;
    selected: boolean;
    slug: string;
  }) => {
    if (selected) {
      deselectPostSearchFilterOption(facet.id, slug);
      if (parentId && selectedSubcategories.length === 1) {
        const parentCategory = categories.find((cat) => cat.id === parentId);
        invariant(parentCategory, 'Missing parent category for subcategory');
        selectPostSearchFilterOption(facet.id, parentCategory.slug);
      }
    } else {
      selectPostSearchFilterOption(facet.id, slug);

      // @ts-expect-error TS(2345): Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
      if (selectedCategoryIds.includes(parentId)) {
        const parent = categories.find((category) => category.id === parentId);

        if (parent) {
          deselectPostSearchFilterOption(facet.id, parent.slug);
        }
      }
    }
  };

  const selectAll = useCallback(() => {
    selectAllPostSearchFilterOptions(facet.id);
  }, [facet.id, selectAllPostSearchFilterOptions]);
  useEffect(() => {
    if (selectedOptions.length === 0) {
      selectAll();
    }
  }, [selectAll, selectedOptions]);

  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (!(categories && categories.length)) {
    return null;
  }

  return (
    <div data-qa-id={`side-filter-facet-${facet.name}`}>
      <PostSearchSideFacetFilterHolder>
        <BodySmall>{categoryLabel || facet.title}</BodySmall>

        {(selectedCategoryIds.length > 0 || selectedSubcategories.length > 0) &&
          !allOptionsSelected && (
            <Button type="button" variant="tertiary" onClick={selectAll}>
              <Text styleAs="h5">
                {
                  /* TRANSLATORS: clear all filters in article search */
                  getText('Clear')
                }
              </Text>
            </Button>
          )}
      </PostSearchSideFacetFilterHolder>

      <SearchFilterSectionFilters data-qa-id="search-filter-section-filters">
        {categories.map(({ slug, icon, id }) => {
          const selected =
            !allOptionsSelected &&
            (selectedOptions.some((option) => slug === option) ||
              (selectedSubcategories.length > 0 &&
                selectedSubcategories[0].parentId === id));

          const onClick = () => {
            if (selected) {
              if (deepEqual([slug], selectedCategorySlugs)) {
                selectAll();
              } else {
                deselectPostSearchFilterOption(facet.id, slug);
                subCategories.forEach((subcategory) => {
                  deselectPostSearchFilterOption(facet.id, subcategory.slug);
                });
              }
            } else {
              setPostSearchFilter(facet.id, [slug]);
            }
          };

          return (
            <PostSearchCategory
              key={slug}
              selected={selected}
              title={facet.optionTitles[slug]}
              icon={icon}
              onClick={onClick}
              qaId="side-filter"
              data-qa-selected={selected}
              data-qa-value={slug}
            />
          );
        })}
      </SearchFilterSectionFilters>

      {subCategories.length > 0 && !allOptionsSelected && (
        <>
          <Divider marginTop={36} marginBottom={36} />

          <PostSearchSideFacetFilterHolder>
            <BodySmall>{getText('Related')}</BodySmall>
          </PostSearchSideFacetFilterHolder>

          <PostSearchSideFacetFilterRelatedHolder>
            {subCategories.map((subcategory) => {
              const selected = selectedOptions.some(
                (option) => subcategory.slug === option,
              );
              return (
                <PillHolder key={subcategory.id}>
                  <Pill
                    onClick={() => toggleCategory({ ...subcategory, selected })}
                    title={sprintf(getText(`Clear %(option)s filter`), {
                      option: subcategory.name,
                    })}
                    text={subcategory.name}
                    active={selected}
                    $outline
                  />
                </PillHolder>
              );
            })}
          </PostSearchSideFacetFilterRelatedHolder>
        </>
      )}
    </div>
  );
}
