import { Box } from 'components/Box';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { PlaceholderImage } from 'components/PlaceholderImage';
import { Text } from 'components/Text/Text';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { MainStorePostSearchStatePostHit } from 'store/postSearch/postSearch';
import { colors, fontWeights } from 'theme/theme';
import { isNotEmpty } from 'utils/functional';

export function PostSearchImage({
  post,
  subsite,
  featured,
}: {
  post: MainStorePostSearchStatePostHit;
  subsite: CmsApiSubsite;
  featured: boolean;
}) {
  const width = featured ? 736 : 344;
  const height = featured ? 440 : 206;

  const postImage = post.thumbnail || post.headerImage;

  if (postImage) {
    return (
      <FilestackImage
        data-qa-id="search-result-image"
        // Download slightly larger images so they don't look grainy
        width={width * 1.25}
        height={height * 1.25}
        data={postImage}
        alt={postImage.altText}
        objectFit="cover"
        expandSize
      />
    );
  }

  if (isNotEmpty(subsite.placeholderImages)) {
    return (
      <PlaceholderImage
        data-qa-id="search-result-image"
        postId={post.objectID}
        placeholderImages={subsite.placeholderImages}
        width={width}
        height={height}
        alt={post.title}
        expandSize
      />
    );
  }

  return (
    <Box
      data-qa-id="search-result-image-fallback"
      position="relative"
      bg={colors.selectionGrey}
    >
      <Box pb={`${(height / width) * 100}%`} />
      <Box
        position="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        <Text
          fontWeight={fontWeights.bold}
          fontSize={96}
          color={colors.elementGrey}
        >
          {post.title.slice(0, 1).toUpperCase()}
        </Text>
      </Box>
    </Box>
  );
}
