import { bindActionCreators } from '@reduxjs/toolkit';

import { Box } from 'components/Box';
import { IncludedSubsites } from 'components/IncludedSubsites';
import { CmsApiCategory } from 'modules/cms/api/types/CmsApiCategory';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { PostSearchCategories } from 'modules/postSearch/components/PostSearchCategories';
import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useMainSelector } from 'store/hooks/useMainSelector';
import {
  deselectPostSearchFilterOption,
  selectAllPostSearchFilterOptions,
  selectPostSearchFilterOption,
  setPostSearchFilter,
} from 'store/postSearch/postSearch.actions';
import { complement, isEmpty } from 'utils/functional';

type Props = {
  subsite: CmsApiSubsite;
};

export function PostSearchFiltersSidebarContainer({ subsite }: Props) {
  const dispatch = useMainDispatch();

  const boundActions = bindActionCreators(
    {
      setSearchFilter: setPostSearchFilter,
      selectAllSearchFilterOptions: selectAllPostSearchFilterOptions,
      selectSearchFilterOption: selectPostSearchFilterOption,
      deselectSearchFilterOption: deselectPostSearchFilterOption,
    },
    dispatch,
  );

  const filters = useMainSelector((state) => state.postSearch.filters);
  const searchFacets = useMainSelector(
    (state) => state.postSearch.searchFacets,
  );
  const facetsThatArentLoaded = useMainSelector(
    (state) => state.postSearch.facetsThatArentLoaded,
  );

  const categoriesFacet = Object.values(searchFacets).filter(
    (key) => key.name === 'categories',
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loaded = (facet: any) =>
    facet.lazy ? !facetsThatArentLoaded.includes(facet.id) : true;

  const hasCategories =
    !isEmpty(categoriesFacet) &&
    !categoriesFacet.some(complement(loaded)) &&
    categoriesFacet.some((c) => !isEmpty(c.options));
  const hasIncludedSubsites = subsite.includedSubsites.length > 0;

  if (!hasIncludedSubsites && !hasCategories) {
    return null;
  }

  let categoryComponent = null;

  if (hasCategories) {
    const allCategories = categoriesFacet[0] as unknown as PostSearchFacetData<
      CmsApiCategory[]
    >;
    const selectedOptions =
      // @ts-expect-error TODO: fix types
      filters[allCategories.id] instanceof Array
        ? // @ts-expect-error TODO: fix types
          (filters[allCategories.id] as string[])
        : [];
    categoryComponent = (
      <PostSearchCategories
        facet={allCategories}
        categoryLabel={subsite.categoryLabel}
        selectedOptions={selectedOptions}
        setPostSearchFilter={boundActions.setSearchFilter}
        selectPostSearchFilterOption={boundActions.selectSearchFilterOption}
        deselectPostSearchFilterOption={boundActions.deselectSearchFilterOption}
        selectAllPostSearchFilterOptions={
          boundActions.selectAllSearchFilterOptions
        }
      />
    );
  }

  return (
    <Box data-qa-id="search-filters" mb="36px">
      <Box px={0} mt={24}>
        {subsite.includedSubsites.length > 0 ? (
          <IncludedSubsites
            subsites={subsite.includedSubsites}
            parentSubsite={subsite}
          />
        ) : (
          categoryComponent
        )}
      </Box>
    </Box>
  );
}
