import styled from 'styled-components';

import { VersionedRouterLink } from 'components/VersionedLink/VersionedRouterLink';
import { colors, transition } from 'theme/theme';

export const ImageLink = styled(VersionedRouterLink)`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const PostLink = styled(VersionedRouterLink)`
  color: ${colors.mediumContentGrey};
  text-decoration: none;
  transition: all ${transition};

  &:hover,
  &:focus {
    color: ${colors.brandBlue};
    text-decoration: none;
  }
`;
