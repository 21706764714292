import { MainStoreState } from 'store/MainStoreState';

export function getPostSearchTrackingData({
  postSearch: { initialized, query, pageIndex, resultsByPage },
}: MainStoreState) {
  if (initialized) {
    const results = resultsByPage[pageIndex.toString()];
    return {
      current_page: pageIndex + 1,
      search_query: query,
      number_of_results: results.nbHits,
      number_of_pages: results.nbPages,
    };
  }

  return {};
}
