import { ParsedQuery } from 'query-string';

import { PostSearchFacetData } from 'modules/postSearch/types/PostSearchFacetData';
import { PostSearchFiltersWithType } from 'modules/postSearch/types/PostSearchFiltersWithType';
import { filterObject, removeKeys, updateObject } from 'utils/functional';

const NO_FILTER_SENTINEL = '_NONE_';

type Args = {
  filters: PostSearchFiltersWithType;
  searchFacets: Record<string, PostSearchFacetData>;
  initialQueryParams: ParsedQuery;
  includeTypeInQueryParams?: boolean;
};

export function postSearchMakeSerializedUrlFilters({
  filters,
  searchFacets,
  initialQueryParams,
  includeTypeInQueryParams,
}: Args) {
  return removeKeys(
    updateObject(
      // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
      ...Object.entries(
        filterObject(
          filters, // ALL is the default value so don't add it to the url
          // UNLESS it is the `type` param, where we use 'ALL'
          (key, value) => value !== 'ALL' || key === 'type',
        ),
      ).map(([key, value]) => {
        const facet = searchFacets[key];

        if (facet && facet.type === 'date') {
          return value;
        }

        if (
          facet &&
          facet.type === 'string' &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          return { [facet.name]: initialQueryParams[facet.name] };
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-nested-ternary
        return facet
          ? // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-nested-ternary
            facet.type === 'array'
            ? // TODO: this case shouldn't exist
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line no-nested-ternary, @typescript-eslint/no-explicit-any
              (value as any).length === 0
              ? {
                  [facet.name]: NO_FILTER_SENTINEL,
                }
              : // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                facet.options.every((i) => (value as any).includes(i)) &&
                  facet.id !== 'jobFamilyFacet' // will be removed: 'ALL' case
                ? {}
                : {
                    [facet.name]: value,
                  }
            : {
                [facet.name]: value,
              }
          : {
              [key]: value,
            };
      }),
    ),
    [includeTypeInQueryParams ? '' : 'type'],
  );
}
