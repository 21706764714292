import { Dispatch } from '@reduxjs/toolkit';

import { executePostSearchQuery } from './executePostSearchQuery';
import {
  MainStorePostSearchState,
  MainStorePostSearchStateResults,
  SearchSuccessAction,
} from './postSearch';

const searchResultsSuccessful = ({
  results,
}: {
  results: MainStorePostSearchStateResults;
}): SearchSuccessAction => ({
  type: 'POST_SEARCH_RESULTS_SUCCESS',
  results,
});

export function search(
  subsiteId: string,
  includedSubsiteIds: Array<string>,
  locale: string,
) {
  return (
    dispatch: Dispatch,
    getState: () => { postSearch: MainStorePostSearchState },
  ) => {
    dispatch({
      type: 'POST_SEARCH_STARTED',
    });
    executePostSearchQuery({
      postSearch: getState().postSearch,
      subsiteId,
      includedSubsiteIds,
      locale,
    }).then((results) => {
      dispatch(
        searchResultsSuccessful({
          results,
        }),
      );
    });
  };
}
