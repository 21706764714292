import styled from 'styled-components';

import { StyledVersionedRouterLink } from 'components/Link/StyledVersionedRouterLink';
import { colors, cssBreakpoints } from 'theme/theme';

export const SubsiteLinkContainer = styled(StyledVersionedRouterLink)`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 80px;
  justify-content: center;
  padding: 0 12px;
  width: 80px;
  color: ${colors.lightContentGrey};
  background-color: ${colors.inputGrey};

  &:hover,
  &:focus {
    color: ${colors.brandBlue} !important;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    border-radius: 0;
    flex-direction: row;
    gap: 12px;
    height: 50px;
    margin: 2px 0;
    width: 100%;
    justify-content: flex-start;
  }
`;

export const PostSearchIncludedSubsitesButtonText = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  text-align: center;

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    font-size: 16px;
  }
`;
