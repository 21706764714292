import queryString from 'query-string';
import { Component, ReactNode } from 'react';
import deepEqual from 'react-fast-compare';
import { ConnectedProps, connect } from 'react-redux';

import { CmsApiPageMini } from 'modules/cms/api/types/CmsApiPage';
import { CmsApiSubsite } from 'modules/cms/api/types/CmsApiSubsite';
import { PostSearchLoadingSection } from 'modules/postSearch/components/Loading/PostSearchLoadingSection';
import { PostSearchResults } from 'modules/postSearch/components/Results/PostSearchResults';
import { postSearchGetQueryString } from 'modules/postSearch/helpers/postSearchGetQueryString';
import { MainStoreState } from 'store/MainStoreState';
import {
  initializePostSearch,
  resultsFn,
  setPostSearchPageIndex,
  setPostSearchQuery,
} from 'store/postSearch/postSearch.actions';
import { search } from 'store/postSearch/search';
import { objectEmpty } from 'utils/functional';
import { getPostSearchTrackingData } from 'utils/getPostSearchTrackingData';
import { RouteComponentProps, withRouter } from 'utils/withRouter';
import { useModal } from 'zustand-stores/modalStore';

import { debouncedTrackDisplayedPosts } from './trackDisplayedPosts';

type OwnProps = {
  children?: ReactNode;
  content: {
    id: string;
    locale: string;
    subsite: CmsApiSubsite;
  };
  parentSubsite: CmsApiSubsite | null | undefined;
};

type PropsFromZustandStore = {
  modalShown: boolean;
};

type Props = OwnProps &
  RouteComponentProps &
  PropsFromRedux &
  PropsFromZustandStore;

class UnconnectedPostSearchContainer extends Component<Props> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  search = () => {
    const { content, dispatchSearch } = this.props;
    const { id: subsiteId, includedSubsites = [] } = content.subsite;

    dispatchSearch(
      subsiteId,
      includedSubsites.map((subsiteMini) => subsiteMini.id),
      content.locale,
    );
  };

  componentDidMount() {
    const { postSearch, location, dispatchSetSearchQuery } = this.props;
    const { initialized, query } = postSearch;

    const { pq: queryParam } = queryString.parse(location.search);

    if (initialized) {
      let performSearch = true;

      if (queryParam !== undefined && queryParam !== query) {
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error TS(2345): Argument of type 'string | (string | null)[] | nul... Remove this comment to see the full error message
        dispatchSetSearchQuery(queryParam, false);
        performSearch = false;
      }

      if (performSearch) {
        this.search();
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const prevSearch = prevProps.postSearch;

    const {
      location,
      postSearch,
      navigate,
      dispatchSetSearchQuery,
      dispatchSetPageIndex,
      modalShown,
    } = this.props;

    const {
      facetsThatArentLoaded,
      filters,
      initialSearchStarted,
      searchFacets,
      query,
    } = postSearch;

    // All asynchronously loaded facets have been loaded
    const facetsAreLoaded = facetsThatArentLoaded.length === 0;

    const filtersHaveChanged =
      !deepEqual(prevSearch.filters, filters) ||
      !deepEqual(prevSearch.searchFacets, searchFacets);

    if (filtersHaveChanged && facetsAreLoaded && initialSearchStarted) {
      const postSearchQueryString = postSearchGetQueryString(
        filters,
        searchFacets,
        query,
        location.search,
      );
      navigate(
        {
          pathname: location.pathname,
          search: postSearchQueryString,
        },
        {
          state: location.state,
          replace: true,
        },
      );
    }

    // This is needed for back button behavior, may be worth revisiting
    const queryHasChanged =
      queryString.parse(location.search).pq !==
      queryString.parse(prevProps.location.search).pq;
    const resultsArentLoaded =
      prevSearch.searchId !== postSearch.searchId ||
      (prevSearch.pageIndex !== postSearch.pageIndex &&
        !postSearch.resultsByPage[postSearch.pageIndex.toString()]);

    if (queryHasChanged) {
      dispatchSetSearchQuery(
        // TODO: Fix this the next time the file is edited.
        // @ts-expect-error TS(2345): Argument of type 'string | (string | null)[] | nul... Remove this comment to see the full error message
        queryString.parse(location.search).pq,
        false,
      );
      this.search();
    } else if (
      facetsAreLoaded &&
      (!initialSearchStarted || resultsArentLoaded)
    ) {
      this.search();
    } else {
      const { page: prevPageParam } = queryString.parse(
        prevProps.location.search,
      );
      const { page: pageParam } = queryString.parse(location.search);
      const { pageIndex: prevPageIndex } = prevSearch;
      const { pageIndex, resultsByPage } = postSearch;
      // @ts-expect-error TS(2345): Argument of type 'string | (string | null)[] | nul... Remove this comment to see the full error message
      const pageIndexParam = (parseInt(pageParam, 10) || 1) - 1;

      if (
        prevPageIndex === pageIndex &&
        prevPageParam !== pageParam &&
        pageIndexParam !== pageIndex
      ) {
        dispatchSetPageIndex(pageIndexParam, true);
      }

      if (objectEmpty(resultsByPage)) {
        return;
      }

      const prevResults = resultsFn(prevSearch);
      const results = resultsFn(postSearch);

      const { content, getSearchTrackingData } = this.props;

      if (
        !modalShown &&
        results &&
        (!prevResults || prevResults.timestamp !== results.timestamp)
      ) {
        debouncedTrackDisplayedPosts(
          results.hits,
          getSearchTrackingData(),
          // @ts-expect-error fix types
          content.subsite,
        );
      }
    }
  }

  render() {
    const { content, postSearch, parentSubsite } = this.props;

    const { subsite } = content;

    // article search is only available when there's a home page, so it's not null
    const homePage = subsite.homePage as CmsApiPageMini;

    return resultsFn(postSearch) ? (
      <PostSearchResults
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        search={postSearch}
        homePageUrl={homePage.url}
        subsite={content.subsite}
        parentSubsite={parentSubsite}
      />
    ) : (
      <PostSearchLoadingSection />
    );
  }
}

const mapStateToProps = (state: MainStoreState) => {
  const { postSearch } = state;
  return {
    postSearch,
    getSearchTrackingData: () => getPostSearchTrackingData(state),
  };
};

const connector = connect(mapStateToProps, {
  dispatchInitializeSearch: initializePostSearch,
  dispatchSearch: search,
  dispatchSetPageIndex: setPostSearchPageIndex,
  dispatchSetSearchQuery: setPostSearchQuery,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

function UnconnectedPostSearchContainerWithModalProps(
  props: Omit<Props, 'modalShown'>,
) {
  const { modal } = useModal();

  return (
    <UnconnectedPostSearchContainer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      modalShown={Boolean(modal)}
    />
  );
}

export const PostSearchContainer = withRouter(
  connector(UnconnectedPostSearchContainerWithModalProps),
);
