import { ReactNode } from 'react';
import styled from 'styled-components';

import { HeadingSmall } from 'components/Text/HeadingSmall';
import { colors } from 'theme/theme';

export const PostSearchResultCategoryLabel = styled(
  ({ className, children }: { className?: string; children: ReactNode }) => (
    <div className={className}>
      {children ? (
        <HeadingSmall
          color={colors.elementGrey}
          fontSize={14}
          letterSpacing="1.4px"
        >
          {children}
        </HeadingSmall>
      ) : null}
    </div>
  ),
)`
  margin: 24px 0 4px;
`;
