import debounce from 'lodash.debounce';

import { MainStorePostSearchStatePostHit } from 'store/postSearch/postSearch';
import { trackEvent } from 'utils/analytics/track/trackEvent';

const TRACK_DISPLAYED_POST_WAIT = 500;

const trackDisplayedPosts = (
  hits: MainStorePostSearchStatePostHit[],
  searchTrackingData: Record<string, unknown>,
  subsite: { id: string; name: string },
) => {
  const articleIds = hits ? hits.map((h) => h.objectID) : [];
  const rankingInfo = hits
    ? // @ts-expect-error Fix types
      hits.map(({ _rankingInfo, objectID }) => ({
        rankingInfo: _rankingInfo,
        objectID,
      }))
    : [];
  trackEvent('Displayed CMS Articles', {
    article_ids: articleIds,
    ranking_info: rankingInfo,
    subsite_id: subsite.id,
    subsite_name: subsite.name,
    page_type: 'Searches for Articles',
    ...searchTrackingData,
  });
};

export const debouncedTrackDisplayedPosts = debounce(
  trackDisplayedPosts,
  TRACK_DISPLAYED_POST_WAIT,
);
